const OME_BASE_URL = process.env.SCREENSHOTS_OME;
const THUMBNAILS_BASE_URL = process.env.THUMBNAILS_URL;

export function omeScreenshotUrl(streamId: number) {
  return `${OME_BASE_URL}/${streamId}/thumb.jpg`;
}


/**
 * Generate a small thumbnail for a stream
 */
export function thumbnail(streamId: number, extension: 'avif' | 'webp' | 'jpg' = 'avif') {
  const preset = 360;

  return `${THUMBNAILS_BASE_URL}/_/${preset}/plain/${omeScreenshotUrl(streamId)}@${extension}`;
}

/**
 * Get a larger screenshot for a stream
 */
export function screenshot(streamId: number, extension: 'avif' | 'webp' | 'jpg' = 'avif') {
  const preset = 1080;

  return omeScreenshotUrl(streamId);
  //return `${THUMBNAILS_BASE_URL}/_/${preset}/plain/${omeScreenshotUrl(streamId)}@${extension}`;
}